import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { MainMenuItem } from '../layout.model';
import { Store } from '@ngrx/store';
import { selectSeason } from '@state/app.selectors';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgClass } from '@angular/common';
import { AppPermissionsDirective } from '@shared/directives/app-permissions.directive';

@Component({
  selector: 'app-layout-menu-item',
  templateUrl: './layout-menu-item.component.html',
  styleUrls: ['./layout-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLinkActive, RouterLink, NgClass, AppPermissionsDirective],
  standalone: true
})
export class LayoutMenuItemComponent {
  @Input({ required: true }) menuItem: MainMenuItem;
  @Input() showFavouritesChildRows = true;
  @Input() favoriteScreens: string[] = [];

  @Output() favoriteScreensChanged: EventEmitter<string> = new EventEmitter<string>();

  showChildRows = signal(false);

  constructor(
    private router: Router,
    private toast: ToastrService,
    private store: Store
  ) {}

  getFavoriteIcon(label: string): string {
    return this.favoriteScreens?.includes(label)
      ? '/assets/icons/menu/favourite-active.svg'
      : '/assets/icons/menu/favourite-inactive.svg';
  }

  linkClicked(event: Event, menuItem: MainMenuItem): void {
    event.stopPropagation();
    if (menuItem.children) {
      if (menuItem.label === 'Favourites') {
        this.showFavouritesChildRows = !this.showFavouritesChildRows;
      } else {
        this.showChildRows.set(!this.showChildRows());
      }
    } else {
      this.navigateToUrl(menuItem);
    }
  }

  navigateToUrl(menuItem: MainMenuItem): void {
    this.store
      .select(selectSeason)
      .pipe(take(1))
      .subscribe((season) => {
        let routerLink = menuItem.routerLink;
        if (routerLink.includes(':seasonCode')) {
          routerLink = routerLink.replace(':seasonCode', season);
        }
        this.router.navigate([routerLink]);
      });
  }

  toggleFavorite(event: Event, menuItem: MainMenuItem): void {
    event.stopPropagation();
    if (this.favoriteScreens.length === 4 && !this.favoriteScreens.includes(menuItem.label)) {
      this.toast.error('You cannot add more than four favourites.');
      return;
    }
    this.favoriteScreensChanged.emit(menuItem?.label);
    this.toast.success('Your list of favourites has been updated.');
  }
}
