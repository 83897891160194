import { createFeature, createReducer, on } from '@ngrx/store';
import { usersInitialState, UsersState } from './users.state';
import { apiAddUserSuccess, apiDeleteUserSuccess, apiLoadUsersSuccess, apiUpdateUserSuccess } from './users.actions';

export const usersReducer = createReducer<UsersState>(
  usersInitialState,
  on(apiLoadUsersSuccess, (state, { users }): UsersState => ({ ...state, users })),
  on(apiAddUserSuccess, (state, { user }): UsersState => ({ ...state, users: [user, ...state.users] })),
  on(
    apiUpdateUserSuccess,
    (state, { user }): UsersState => ({
      ...state,
      users: state.users.map((u) => (u.id === user.id ? user : u))
    })
  ),
  on(
    apiDeleteUserSuccess,
    (state, { userId }): UsersState => ({
      ...state,
      users: state.users.filter((user) => user.id !== userId)
    })
  )
);

export const usersFeature = createFeature({
  name: 'users',
  reducer: usersReducer
});
