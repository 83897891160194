import { Injectable } from '@angular/core';
import { JSONSchema, StorageMap } from '@ngx-pwa/local-storage';
import { Observable } from 'rxjs';

export enum StorageRecordName {
  UserAccessTokens = 'opUserTokens',
  UserAccessTokensRefreshStarted = 'opUserTokensRefreshStarted'
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(private storage: StorageMap) {}

  isBackingEnginePersistent(): boolean {
    return this.storage.backingEngine === 'indexedDB' || this.storage.backingEngine === 'localStorage';
  }

  get<T>(key: StorageRecordName, schema?: JSONSchema, keyModifier?: string): Observable<T | undefined> {
    let storeKey: string = key;
    if (keyModifier) {
      storeKey += keyModifier;
    }
    return this.storage.get<T>(storeKey, schema);
  }

  watch<T>(key: StorageRecordName, schema?: JSONSchema, keyModifier?: string): Observable<T | undefined> {
    let storeKey: string = key;
    if (keyModifier) {
      storeKey += keyModifier;
    }
    return this.storage.watch<T>(storeKey, schema);
  }

  set<T>(key: StorageRecordName, data: T, schema?: JSONSchema, keyModifier?: string): Observable<undefined> {
    let plainData: T = data;
    if (Array.isArray(data) && data !== null) {
      plainData = data;
    } else if (typeof data === 'object' && data !== null) {
      plainData = { ...data };
    }
    let storeKey: string = key;
    if (keyModifier) {
      storeKey += keyModifier;
    }
    return this.storage.set(storeKey, plainData, schema);
  }

  delete(key: StorageRecordName, keyModifier?: string): Observable<undefined> {
    let storeKey: string = key;
    if (keyModifier) {
      storeKey += keyModifier;
    }
    return this.storage.delete(storeKey);
  }

  clear(): Observable<undefined> {
    return this.storage.clear();
  }
}
