import { Injectable, NgZone } from '@angular/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { BehaviorSubject, interval } from 'rxjs';

export type NewVersionEvent =
  | 'newVersionDownloading'
  | 'readyToApplyNewVersion'
  | 'installationFailed'
  | 'noNewVersionDetected';

@Injectable({ providedIn: 'root' })
export class NewVersionCheckerService {
  private updateAvailableBehaviourSubject = new BehaviorSubject<NewVersionEvent>('noNewVersionDetected');
  updateAvailable$ = this.updateAvailableBehaviourSubject.asObservable();

  constructor(private swUpdate: SwUpdate, private zone: NgZone) {
    this.checkForUpdate();
  }

  checkForUpdate(): void {
    if (!this.swUpdate.isEnabled) {
      return;
    }
    this.swUpdate.versionUpdates.subscribe((versionEvent) => {
      this.handleUpdateEvent(versionEvent);
    });

    this.zone.runOutsideAngular(() => {
      // every 5 minutes
      interval(300 * 1000).subscribe(() => {
        this.swUpdate.checkForUpdate();
      });
    });
  }

  handleUpdateEvent(versionEvent: VersionEvent): void {
    switch (versionEvent.type) {
      case 'VERSION_DETECTED':
        this.updateAvailableBehaviourSubject.next('newVersionDownloading');
        break;
      case 'VERSION_READY':
        this.updateAvailableBehaviourSubject.next('readyToApplyNewVersion');
        break;
      case 'VERSION_INSTALLATION_FAILED':
        this.updateAvailableBehaviourSubject.next('installationFailed');
        break;
      case 'NO_NEW_VERSION_DETECTED':
        this.updateAvailableBehaviourSubject.next('noNewVersionDetected');
        break;
    }
  }
}
