@if (!(menuItem.label === 'Favourites' && !menuItem.children.length)) {
  <div class="item" routerLinkActive="active" (click)="linkClicked($event, menuItem)">
    @if (!menuItem.children) {
      <div [routerLink]="menuItem.routerLink"></div>
    }
    <div class="icon">
      <span [ngClass]="menuItem.icon"></span>
    </div>
    <div class="label">{{ menuItem.label }}</div>
    @if (menuItem.children) {
      <div
        class="arrow"
        [class.active]="
          (menuItem.label === 'Favourites' && showFavouritesChildRows) ||
          (menuItem.label !== 'Favourites' && showChildRows())
        "></div>
      <span class="icon-arrow"></span>
    }
  </div>
}
@if (
  (menuItem.label === 'Favourites' && showFavouritesChildRows) || (menuItem.label !== 'Favourites' && showChildRows())
) {
  @for (childRoute of menuItem.children; track childRoute.label) {
    <div
      *appHasPermissions="childRoute.permissionsRequired; identifier: 'Side menu ' + childRoute.label"
      class="submenu-item-container"
      (click)="navigateToUrl(childRoute)">
      <div class="item submenu">
        <div [routerLink]="childRoute.routerLink" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">
          <div class="label">{{ childRoute.label }}</div>
        </div>
      </div>
      <img
        class="icon-favorite"
        [src]="getFavoriteIcon(childRoute.label)"
        alt="icon-favorite"
        (click)="toggleFavorite($event, childRoute)" />
    </div>
  }
}
