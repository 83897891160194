import { ChangeDetectionStrategy, Component, computed, OnInit, signal } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ButtonsModule } from '@shared/components/buttons/buttons.module';
import { NewVersionCheckerService, NewVersionEvent } from '@shared/services/new-version-checker.service';
import { NgIf } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';

const updateMessages: [NewVersionEvent, string][] = [
  ['newVersionDownloading', 'Downloading new app version...'],
  ['readyToApplyNewVersion', 'The app update is available...'],
  ['installationFailed', 'Failed to automatically update the app. Please refresh the page manually.'],
  ['noNewVersionDetected', 'No new version detected']
];

@UntilDestroy()
@Component({
  selector: 'app-update-available',
  standalone: true,
  templateUrl: './update-available.component.html',
  styleUrl: './update-available.component.scss',
  imports: [ButtonsModule, NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateAvailableComponent implements OnInit {
  newVersionStatus = signal<NewVersionEvent>('noNewVersionDetected');
  isNewVersionAvailable = computed(() => this.newVersionStatus() !== 'noNewVersionDetected');

  forceBrowserReload = false;
  newVersionCheckerSubscription: Subscription;

  constructor(private newVersionCheckerService: NewVersionCheckerService, private swUpdate: SwUpdate) {}

  ngOnInit(): void {
    this.newVersionCheckerSubscription = this.newVersionCheckerService.updateAvailable$
      .pipe(untilDestroyed(this))
      .subscribe((newVersionStatus) => {
        this.newVersionStatus.set(newVersionStatus);
        if (newVersionStatus === 'readyToApplyNewVersion' || newVersionStatus === 'installationFailed') {
          this.newVersionCheckerSubscription.unsubscribe();
        }
      });
  }

  get newVersionMessage(): string {
    return updateMessages.find(([status]) => status === this.newVersionStatus())[1];
  }

  reloadBtnPressed(): void {
    if (this.forceBrowserReload) {
      document.location.reload();
    } else {
      this.applyNewVersionUpdate();
    }
  }

  applyNewVersionUpdate(): void {
    this.swUpdate
      .activateUpdate()
      .then(() => document.location.reload())
      .then(() => {
        this.forceBrowserReload = false;
      })
      .catch(() => {
        this.forceBrowserReload = true;
      });
  }
}
