import { createFeature, createReducer, on } from '@ngrx/store';
import { growthSlotsInitialState, GrowthSlotsState } from '@features/slots/state/growth-slots.state';
import {
  apiGrowthSlotsAirportCurfewsFetched,
  apiGrowthSlotsAirportViewSlotChanged,
  apiGrowthSlotsCurfewsUpdated,
  apiGrowthSlotsDetailsAirportsFetchSuccess,
  apiGrowthSlotsNewProfileAdded,
  apiGrowthSlotsProfileRemoved,
  apiGrowthSlotsProfilesFetchSuccess,
  apiGrowthSlotsSlotsFetchSuccess,
  apiGrowthSlotsSlotUpdated,
  apiGrowthSlotsSlotUpdatedForUpdate,
  growthSlotsClickedProfileSuccess,
  growthSlotsCurfewsAddNewRowClicked,
  growthSlotsCurfewsArrivalDepartureChangedOnDraftRow,
  growthSlotsCurfewsCancelEditingRowClicked,
  growthSlotsCurfewsDayClickedOnDraftRow,
  growthSlotsMainFiltersParamsChanged,
  growthSlotsMainPageDestroyed,
  growthSlotsMainPageInitializedSuccess,
  growthSlotsMainTabChangedSuccess,
  growthSlotsSlotDetailsAddNewRowCancel,
  growthSlotsSlotDetailsClickedAddNewRow,
  growthSlotsSlotDetailsNewRowDaysChanged,
  growthSlotsSlotDetailsNewRowZonesChanged
} from '@features/slots/state/growth-slots.actions';
import { CurfewAirportDto } from '@features/slots/slots.models';
import { areArraysEqualWithSort } from '@utils/utils';
import { SlotsMainService } from '@features/slots/services/slots-main.service';

export const growthSlotsReducer = createReducer<GrowthSlotsState>(
  growthSlotsInitialState,
  on(apiGrowthSlotsProfilesFetchSuccess, (state, action): GrowthSlotsState => {
    const slotProfiles = [...action.slotsProfiles];
    slotProfiles.sort((a, b) => b.id - a.id);
    return {
      ...state,
      slotProfiles
    };
  }),
  on(apiGrowthSlotsNewProfileAdded, (state, action): GrowthSlotsState => {
    return {
      ...state,
      slotProfiles: [action.profile, ...state.slotProfiles]
    };
  }),
  on(apiGrowthSlotsProfileRemoved, (state, action): GrowthSlotsState => {
    return {
      ...state,
      slotProfiles: state.slotProfiles.filter((profile) => profile.id !== action.profileId)
    };
  }),
  on(growthSlotsClickedProfileSuccess, (state, action): GrowthSlotsState => {
    return {
      ...state,
      slotName: action.profile.name
    };
  }),
  on(growthSlotsMainPageInitializedSuccess, growthSlotsMainFiltersParamsChanged, (state, action): GrowthSlotsState => {
    return {
      ...state,
      slotName: 'slotName' in action ? action.slotName ?? state.slotName : state.slotName,
      selectedTab: 'currentTab' in action ? action.currentTab ?? state.selectedTab : state.selectedTab,
      isLocalTimeZone: action.isLocalTimeZone ?? state.isLocalTimeZone,
      dropdownFilterAirports: action.dropdownFilterAirports ?? state.dropdownFilterAirports
    };
  }),
  on(growthSlotsMainPageDestroyed, (state): GrowthSlotsState => {
    return {
      slotProfiles: state.slotProfiles,
      ...growthSlotsInitialState
    };
  }),
  on(growthSlotsMainTabChangedSuccess, (state, action): GrowthSlotsState => {
    return {
      ...state,
      selectedTab: action.newTab
    };
  }),
  on(apiGrowthSlotsSlotsFetchSuccess, (state, action): GrowthSlotsState => {
    return {
      ...state,
      airportSlot: action.airportSlot
    };
  }),
  on(apiGrowthSlotsAirportViewSlotChanged, (state, action): GrowthSlotsState => {
    return {
      ...state,
      airportSlot: {
        ...state.airportSlot,
        days: state.airportSlot.days.map((day) => {
          if (day.day === action.day.day) {
            let arrival_slots_per_hour: number[] = day.arrival_slots_per_hour;
            let departure_slots_per_hour: number[] = day.departure_slots_per_hour;
            if (action.slotType === 'arrival') {
              arrival_slots_per_hour = [...action.day.arrival_slots_per_hour];
              arrival_slots_per_hour[action.i] = action.zone;
            } else if (action.slotType === 'departure') {
              departure_slots_per_hour = [...departure_slots_per_hour];
              departure_slots_per_hour[action.i] = action.zone;
            }
            return {
              ...day,
              arrival_slots_per_hour,
              departure_slots_per_hour
            };
          } else {
            return day;
          }
        })
      }
    };
  }),
  on(apiGrowthSlotsAirportCurfewsFetched, (state, action): GrowthSlotsState => {
    return {
      ...state,
      airportCurfews: action.airportCurfews
    };
  }),
  on(growthSlotsCurfewsAddNewRowClicked, (state, action): GrowthSlotsState => {
    if (state.airportCurfewDraft) {
      return state;
    }

    let airportCurfewDraft = undefined;
    return {
      ...state,
      airportCurfews: state.airportCurfews.map((curfewAirport) => {
        if (curfewAirport.airport_code !== action.airportCode) {
          return curfewAirport;
        }
        const updatedAirportCurfew: CurfewAirportDto = {
          ...curfewAirport,
          curfews: [
            ...curfewAirport.curfews,
            {
              days: [],
              arrival_window: {
                open_minutes: undefined,
                close_minutes: undefined,
                open_is_next_day: false,
                close_is_next_day: false
              },
              departure_window: {
                open_minutes: undefined,
                close_minutes: undefined,
                open_is_next_day: false,
                close_is_next_day: false
              }
            }
          ]
        };
        airportCurfewDraft = updatedAirportCurfew;
        return updatedAirportCurfew;
      }),
      airportCurfewDraft: airportCurfewDraft
    };
  }),
  on(apiGrowthSlotsCurfewsUpdated, (state): GrowthSlotsState => {
    return {
      ...state,
      airportCurfewDraft: undefined
    };
  }),
  on(growthSlotsCurfewsCancelEditingRowClicked, (state, action): GrowthSlotsState => {
    return {
      ...state,
      airportCurfews: state.airportCurfews.map((curfewAirport) => {
        if (curfewAirport.airport_code !== action.cancelledCurfewView.airport_code) {
          return curfewAirport;
        }
        return {
          ...curfewAirport,
          curfews: curfewAirport.curfews.filter((curfew) => curfew !== action.cancelledCurfewView.curfew)
        };
      }),
      airportCurfewDraft: undefined
    };
  }),
  on(growthSlotsCurfewsDayClickedOnDraftRow, (state, action): GrowthSlotsState => {
    return {
      ...state,
      airportCurfews: state.airportCurfews.map((curfewAirport) => {
        if (curfewAirport.airport_code !== action.updatedCurfew.airport_code) {
          return curfewAirport;
        }
        return {
          ...curfewAirport,
          curfews: curfewAirport.curfews.map((curfew, index) => {
            if (index < curfewAirport.curfews.length - 1) {
              return curfew;
            }
            if (!areArraysEqualWithSort(curfew.days, action.updatedCurfew.curfew.days)) {
              return curfew;
            }
            return {
              ...curfew,
              days: curfew.days.includes(action.day)
                ? curfew.days.filter((d) => d !== action.day)
                : [...curfew.days, action.day]
            };
          })
        };
      })
    };
  }),
  on(growthSlotsCurfewsArrivalDepartureChangedOnDraftRow, (state, action): GrowthSlotsState => {
    return {
      ...state,
      airportCurfews: state.airportCurfews.map((curfewAirport) =>
        SlotsMainService.updateCurfewAirportIfNecessary(curfewAirport, action)
      )
    };
  }),
  on(apiGrowthSlotsDetailsAirportsFetchSuccess, (state, action): GrowthSlotsState => {
    return {
      ...state,
      slotsDetailsAirports: action.growthSlotsDetailsAirports,
      slotsDetailsAirportRowDraft: undefined
    };
  }),
  on(apiGrowthSlotsSlotUpdatedForUpdate, (state, action): GrowthSlotsState => {
    return {
      ...state,
      slotsDetailsAirports: state.slotsDetailsAirports.map((airport) => {
        if (airport.airport_code !== action.airportSlot.airport_code) {
          return airport;
        }
        return {
          ...airport,
          slots: airport.slots.map((slot) => {
            const returnedSlot = action.airportSlot.days.find((day) => slot.days.includes(day.day));
            if (!returnedSlot) return slot;
            return {
              ...slot,
              departure_slots_per_hour: returnedSlot.departure_slots_per_hour,
              arrival_slots_per_hour: returnedSlot.arrival_slots_per_hour
            };
          })
        };
      })
    };
  }),
  on(apiGrowthSlotsSlotUpdated, (state, action): GrowthSlotsState => {
    return {
      ...state,
      slotsDetailsAirports: state.slotsDetailsAirports.map((airport) => {
        if (airport.airport_code !== action.airport) {
          return airport;
        }
        return {
          ...airport,
          slots: airport.slots.map((slot) => {
            const isDayMatched = action.curfew.day.some((day) => slot.days.includes(day));
            if (!isDayMatched) return slot;
            if (action.slotType === 'departure') {
              return {
                ...slot,
                departure_slots_per_hour: action.curfew.departure_slots_per_hour
              };
            } else if (action.slotType === 'arrival') {
              return {
                ...slot,
                arrival_slots_per_hour: action.curfew.arrival_slots_per_hour
              };
            }
            return slot;
          })
        };
      })
    };
  }),
  on(growthSlotsSlotDetailsClickedAddNewRow, (state, action): GrowthSlotsState => {
    return {
      ...state,
      slotsDetailsAirportRowDraft: action.airport.airport,
      slotsDetailsAirports: state.slotsDetailsAirports.map((airport) => {
        if (airport.airport_code !== action.airport.airport) {
          return airport;
        }
        return {
          ...airport,
          slots: [
            ...airport.slots,
            {
              days: [],
              arrival_slots_per_hour: new Array(24).fill(0),
              departure_slots_per_hour: new Array(24).fill(0)
            }
          ]
        };
      })
    };
  }),
  on(growthSlotsSlotDetailsNewRowDaysChanged, (state, action): GrowthSlotsState => {
    return {
      ...state,
      slotsDetailsAirports: state.slotsDetailsAirports.map((airport) => {
        if (airport.airport_code !== action.airport) {
          return airport;
        }
        return {
          ...airport,
          slots: airport.slots.map((slot) => {
            if (slot !== action.slots) {
              return slot;
            }
            return {
              ...slot,
              days: action.days
            };
          })
        };
      })
    };
  }),
  on(growthSlotsSlotDetailsNewRowZonesChanged, (state, action): GrowthSlotsState => {
    return {
      ...state,
      slotsDetailsAirports: state.slotsDetailsAirports.map((airport) => {
        if (airport.airport_code !== action.airport) {
          return airport;
        }
        return {
          ...airport,
          slots: airport.slots.map((slot) => {
            if (slot.days !== action.curfew.day) {
              return slot;
            }
            return {
              ...slot,
              arrival_slots_per_hour:
                'arrival_slots_per_hour' in action.curfew
                  ? action.curfew.arrival_slots_per_hour
                  : slot.arrival_slots_per_hour,
              departure_slots_per_hour:
                'departure_slots_per_hour' in action.curfew
                  ? action.curfew.departure_slots_per_hour
                  : slot.departure_slots_per_hour
            };
          })
        };
      })
    };
  }),
  on(growthSlotsSlotDetailsAddNewRowCancel, (state): GrowthSlotsState => {
    return {
      ...state,
      slotsDetailsAirportRowDraft: undefined,
      slotsDetailsAirports: state.slotsDetailsAirports.map((airport) => {
        if (airport.airport_code !== state.slotsDetailsAirportRowDraft) {
          return airport;
        }
        const slicedSlots = [...airport.slots];
        slicedSlots.pop();
        return {
          ...airport,
          slots: slicedSlots
        };
      })
    };
  })
);

export const growthSlotsFeature = createFeature({
  name: 'growth-slots',
  reducer: growthSlotsReducer
});
