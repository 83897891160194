import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectCurrentUser } from '@state/app.selectors';
import { map, Observable, skipWhile } from 'rxjs';
import { take } from 'rxjs/operators';

export const adminRedirectGuard = (): Observable<boolean | UrlTree> => {
  const store = inject(Store);
  const router = inject(Router);

  return store.select(selectCurrentUser).pipe(
    skipWhile((user, index) => {
      return !user && index < 1;
    }),
    take(1),
    map((user) => {
      if (!user) {
        return router.createUrlTree(['/login']);
      } else if (!user.roles?.includes('ROLE_ADMIN')) {
        return router.createUrlTree(['/schedules']);
      } else {
        return true;
      }
    })
  );
};
