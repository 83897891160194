import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, mergeMap, exhaustMap, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { UsersHttpService } from '../services/users.http.service';
import {
  usersScreenInitialized,
  apiLoadUsersSuccess,
  apiLoadUsersFailed,
  addUserButtonClicked,
  apiAddUserSuccess,
  addUserFailed,
  updateUserButtonClicked,
  apiUpdateUserSuccess,
  apiUpdateUserFailed,
  deleteUserButtonClicked,
  apiDeleteUserSuccess,
  apiDeleteUserFailed
} from './users.actions';

@Injectable()
export class UsersEffects {
  constructor(
    private actions$: Actions,
    private usersService: UsersHttpService,
    private toastr: ToastrService
  ) {}

  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersScreenInitialized),
      exhaustMap(() =>
        this.usersService.getAllUsers().pipe(
          map((users) => apiLoadUsersSuccess({ users })),
          catchError(() => of(apiLoadUsersFailed()))
        )
      )
    )
  );

  addUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addUserButtonClicked),
      concatMap((action) =>
        this.usersService.addUser(action.user).pipe(
          map((user) => {
            this.toastr.success('User added successfully.');
            return apiAddUserSuccess({ user });
          }),
          catchError(() => of(addUserFailed()))
        )
      )
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserButtonClicked),
      concatMap((action) =>
        this.usersService.updateUser(action.user.id, action.user).pipe(
          map((user) => {
            this.toastr.success('User updated successfully.');
            return apiUpdateUserSuccess({ user });
          }),
          catchError(() => of(apiUpdateUserFailed()))
        )
      )
    )
  );

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteUserButtonClicked),
      mergeMap((action) =>
        this.usersService.deleteUser(action.userId).pipe(
          map(() => {
            this.toastr.success('User deleted successfully.');
            return apiDeleteUserSuccess({ userId: action.userId });
          }),
          catchError(() => of(apiDeleteUserFailed()))
        )
      )
    )
  );
}
