<app-update-available />
<ng-container *ngrxLet="viewModel$ as viewModel">
  <header>
    <div class="hamburger">
      <label class="burger burger2" for="burger2">
        <input class="hidden" type="checkbox" [(ngModel)]="expandMenu" id="burger2" />
        <span></span>
      </label>
    </div>

    <div class="main-nav">
      <div class="main-nav-left">
        <div class="logo" (click)="navigateToMainPage()"></div>
        @if (!isSeasonHidden) {
          <div class="seasons">
            <app-season-select></app-season-select>
          </div>
        }
      </div>

      <div class="actions">
        <app-user-avatar [name]="viewModel.userName" [initials]="viewModel.userInitials"></app-user-avatar>

        <div class="arrow" (click)="openLogoutMenu($event)">
          <span class="icon-chevron-down"></span>
        </div>

        <app-context-menu #menu [items]="navbarContextMenu" [width]="'132px'"></app-context-menu>
      </div>
    </div>
  </header>

  <nav [class.expanded]="expandMenu">
    <div class="heading">
      <div class="close" (click)="toggleMenu($event)">
        <span class="icon-close"></span>
      </div>
      <div class="logo"></div>
    </div>

    <div class="list">
      @for (menuItem of viewModel.menuItems; let index = $index; track menuItem.label) {
        <app-layout-menu-item
          *appHasPermissions="menuItem.permissionsRequired; identifier: 'Side menu ' + menuItem.label"
          [favoriteScreens]="viewModel.favoriteScreens"
          (favoriteScreensChanged)="favoriteScreenToggled($event)"
          [menuItem]="menuItem" />
      }
    </div>
  </nav>
</ng-container>
<div class="main-wrapper">
  <router-outlet></router-outlet>
</div>
