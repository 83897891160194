import { ChangeDetectionStrategy, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MenuItem } from '@shared/components/context-menu/context-menu.models';
import { ContextMenuComponent } from '@shared/components/context-menu/context-menu.component';
import { OnShortcutPressed, SharedListenerService, Shortcut } from '@shared/services/shared-listener.service';
import * as Sentry from '@sentry/angular';
import { environment } from '@environments/environment';
import { BaseComponent } from '@shared/components/base/base.component';
import { browserToggleFullscreen, favoriteScreenToggled, layoutComponentLogoutActionClicked } from '@state/app.actions';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { selectCurrentUser, selectFullscreen, selectLayoutComponentViewModel } from '@state/app.selectors';
import { FullscreenService } from '@shared/services/fullscreen.service';
import { LoginUserDataDto } from '@authorization/services/auth-token.models';
import { BASIC_SCHEDULER_URL } from '@features/components-routing.module';
import { SettingsHttpService } from '@features/settings/services/settings.http.service';
import { ToastrService } from 'ngx-toastr';
import { LayoutMenuItemComponent } from '@features/layout/layout-menu-item/layout-menu-item.component';
import { AppPermissionsDirective } from '@shared/directives/app-permissions.directive';
import { AsyncPipe } from '@angular/common';
import { UpdateAvailableComponent } from '@shared/components/update-available/update-available.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';

export interface LayoutComponentViewModel<T> {
  userName: string;
  userInitials: string;
  favoriteScreens: string[];
  menuItems: MenuItem<T>[];
}

@UntilDestroy()
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  providers: [SharedListenerService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    LayoutMenuItemComponent,
    AppPermissionsDirective,
    AsyncPipe,
    RouterOutlet,
    UpdateAvailableComponent,
    FormsModule,
    SharedModule,
    ContextMenuComponent
  ],
  standalone: true
})
export class LayoutComponent<T> extends BaseComponent implements OnInit, OnShortcutPressed {
  @ViewChild('menu') menuViewChild: ContextMenuComponent<T>;

  viewModel$ = this.store.select<LayoutComponentViewModel<T>>(selectLayoutComponentViewModel);

  expandMenu = false;
  navbarContextMenu: MenuItem<T>[] = [
    {
      id: 'cache',
      label: 'Clear cache',
      command: this.onClearCacheButtonClick.bind(this),
      permissionsRequired: 'support'
    },
    { id: 'docs', label: 'Documentation', command: this.goToDocumentation.bind(this) },
    { id: 'logout', label: 'Log Out', command: this.logout.bind(this) }
  ];

  isSeasonHidden: boolean;

  constructor(
    private router: Router,
    private sharedShortcutsService: SharedListenerService,
    private settingsHttpService: SettingsHttpService,
    private fullscreenService: FullscreenService,
    private toastService: ToastrService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getUserConfiguration();
    this.hideSeasonForBasicScheduler();

    this.router.events.pipe(untilDestroyed(this)).subscribe((routerEvent) => {
      if (routerEvent instanceof NavigationEnd) {
        this.expandMenu = false;
        this.hideSeasonForBasicScheduler();
      }
    });
  }

  private hideSeasonForBasicScheduler(): void {
    this.isSeasonHidden = this.router.url.includes(BASIC_SCHEDULER_URL);
  }

  getUserConfiguration(): void {
    this.store
      .select(selectCurrentUser)
      .pipe(
        untilDestroyed(this),
        filter((user) => !!user),
        distinctUntilChanged()
      )
      .subscribe((user) => {
        this.registerShortcuts();
        this.configureSentryScope(user);
        this.listenToFullscreen();
      });
  }

  favoriteScreenToggled(screen: string): void {
    this.store.dispatch(favoriteScreenToggled({ screen }));
  }

  private configureSentryScope(user: LoginUserDataDto): void {
    if (environment.name === 'local') return;
    if (!user) return;
    const scope = Sentry.getCurrentScope();
    scope.setUser({
      id: user.id,
      email: user.email,
      username: user.username
    });
    const { hostname } = new URL(window.location.href);
    scope.setTag('host', hostname);
  }

  private registerShortcuts(): void {
    const availableShortcuts: Shortcut[] = [{ identifier: 'openSideMenu', eventName: 'keydown.control.m' }];
    this.sharedShortcutsService.startListening(this, 'document', availableShortcuts);
  }

  private listenToFullscreen(): void {
    this.store
      .select(selectFullscreen)
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe((fullscreen) => {
        if (!this.fullscreenService.isEnabled) {
          return;
        }
        if (fullscreen === true) {
          this.fullscreenService.request();
        } else {
          this.fullscreenService.exit();
        }
      });
    this.fullscreenService.listen(() => {
      this.store.dispatch(browserToggleFullscreen({ isFullscreen: this.fullscreenService.isFullscreen }));
    });
  }

  @HostListener('document:click')
  hideMenuOnClick(): void {
    this.hideMenu();
  }

  navigateToMainPage(): void {
    this.router.navigate(['schedules']);
  }

  onClearCacheButtonClick(): void {
    this.settingsHttpService.clearCache().subscribe(() => {
      this.toastService.success('Cache cleared successfully');
    });
  }

  toggleMenu(e?: Event): void {
    e?.stopPropagation();
    this.expandMenu = !this.expandMenu;
  }

  hideMenu(): void {
    this.expandMenu = false;
  }

  logout(): void {
    this.store.dispatch(layoutComponentLogoutActionClicked());
  }

  goToDocumentation(): void {
    const { hostname } = new URL(window.location.href);
    const tenant = hostname.split('.')[0];
    window.location.href = `https://khimba.github.io/optifly-documentation/login/?tenant=${tenant}`;
  }

  openLogoutMenu(e: Event): void {
    if (this.menuViewChild.isOpen) {
      this.menuViewChild.hide();
    } else {
      this.menuViewChild.toggle(e, null, true);
    }
  }

  onShortcutUsed(code: Shortcut): void {
    if (code.eventName === 'keydown.control.m') {
      this.toggleMenu();
    }
  }
}
