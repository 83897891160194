import { DataGridColumn, FilterOperation } from '@shared/components/data-grid/data-grid.interface';
import DataSource from 'devextreme/data/data_source';
import { enableValidationOnAddingRows } from '@shared/components/data-grid/data-grid.validations';

export class UsersConfiguration {
  static getUsersConfiguration(userRoles: DataSource, isSupportAccount: boolean): DataGridColumn[] {
    const columns: DataGridColumn[] = [
      {
        field: 'first_name',
        validation: [{ type: 'required' }]
      },
      {
        field: 'last_name',
        validation: [{ type: 'required' }]
      },
      {
        field: 'username',
        validation: [{ type: 'required' }]
      },
      {
        field: 'password',
        allowFiltering: false,
        editorOptions: { mode: 'password' },
        cellTemplate: 'passwordTemplate',
        cellTemplatePlaceholder: 'Hidden password',
        validation: [
          {
            type: 'custom',
            message: 'Password is required',
            callback: enableValidationOnAddingRows
          },
          {
            type: 'pattern',
            pattern: /(?=.*([A-Z])+)(?=.*[!@#$&*]+)(?=.*\d+)(?=.*[a-z]+).{8,24}/i,
            message:
              'Password should be min 8 characters long and contain at least one uppercase, number and special character'
          }
        ]
      },
      {
        field: 'email',
        validation: [{ type: 'required' }, { type: 'email' }]
      },
      { field: 'phone' },
      {
        field: 'roles',
        caption: 'Auth Groups',
        cellType: 'dx-tag-box',
        cellTemplate: 'rolesTemplate',
        editCellTemplate: 'rolesEditTemplate',
        validation: [{ type: 'required' }],
        cellDataArray: {
          dataSource: userRoles,
          displayExpr: 'name',
          valueExpr: 'value'
        },
        filterFunction: (value: unknown, _operation: FilterOperation) => ['roles', 'contains', value]
      }
    ];
    if (isSupportAccount) {
      columns.push({ field: 'features', cellType: 'dx-tag-box', allowEditing: false });
    }
    return columns;
  }
}
