import { BaseObject } from '@shared/models/base-object.model';
import { OptiflyPermission } from '@shared/directives/app-permissions.directive';
import DataSource from 'devextreme/data/data_source';

export interface UserView extends BaseObject {
  enabled: boolean;
  last_name: string;
  first_name: string;
  email: string;
  phone: string | null;
  password?: string;
  username: string;
  roles: string[];
  favorite_screens?: string[];
  support_user: boolean;
  features: OptiflyPermission[];
}

export interface UserUpdateData extends UserView {
  new_roles: string[];
  password?: string;
}

export interface UsersViewModel {
  users: UserView[];
  isSupportAccount: boolean;
  userRoles: DataSource;
}

export const USER_ROLES: string[] = ['ROLE_ADMIN', 'ROLE_USER_RW', 'ROLE_USER_RO'];
