import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { Store } from '@ngrx/store';
import { SlotsHttpService } from '@features/slots/services/slots.http.service';
import { catchError, concatMap, finalize, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { selectSeason } from '@state/app.selectors';
import {
  apiGrowthSlotsAirportCurfewsFetched,
  apiGrowthSlotsAirportViewSlotChanged,
  apiGrowthSlotsAirportViewSlotChangedFailed,
  apiGrowthSlotsCurfewsDayChanged,
  apiGrowthSlotsCurfewsUpdated,
  apiGrowthSlotsDetailsAirportsFetchSuccess,
  apiGrowthSlotsProfilesFetchSuccess,
  apiGrowthSlotsSlotBulkUpdated,
  apiGrowthSlotsSlotDetailsAddedNewRow,
  apiGrowthSlotsSlotsFetchSuccess,
  apiGrowthSlotsSlotUpdated,
  apiGrowthSlotsSlotUpdatedForUpdate,
  growthSlotsClickedProfile,
  growthSlotsClickedProfileSuccess,
  growthSlotsCurfewsArrivalDepartureChanged,
  growthSlotsCurfewsConcurrentValueChanged,
  growthSlotsCurfewsDayClicked,
  growthSlotsCurfewsSaveRowClicked,
  growthSlotsCurfewsToggle24hClicked,
  growthSlotsMainDropdownSelectionRemoved,
  growthSlotsMainDropdownSelectionRemovedSuccess,
  growthSlotsMainFiltersParamsChanged,
  growthSlotsMainMultipleAirportsSelectionChanged,
  growthSlotsMainPageChangedIsLocalTimeZone,
  growthSlotsMainPageInitialized,
  growthSlotsMainPageInitializedSuccess,
  growthSlotsMainTabChanged,
  growthSlotsMainTabChangedSuccess,
  growthSlotsSlotDetailsAddNewRowSave,
  growthSlotsSlotDetailsCloneToAll,
  growthSlotsSlotDetailsExistingDaysChanged,
  growthSlotsSlotDetailsExistingZonesChanged,
  growthSlotsSlotDetailsRelaxAllArrivalsClicked,
  growthSlotsSlotDetailsRelaxAllDeparturesClicked,
  growthSlotsSlotDetailsRestricsAllDeparturesClicked,
  growthSlotsSlotDetailsRestrictAllArrivalsClicked,
  schedulPlanGrowthSlotsModalChanged,
  schedulPlanGrowthSlotsModalDisplayed,
  slotsAirportViewZoneChanged,
  slotsProfilesPageInitialized
} from '@features/slots/state/growth-slots.actions';
import { MergedRoute, selectCurrentRouteState } from '@state/router.selector';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { Router } from '@angular/router';
import { growthSlotsTabs, SlotsRoute } from '@features/slots/state/growth-slots.state';
import { ToastrService } from 'ngx-toastr';
import { AddCurfew, UpdateSlotModel } from '@features/slots/slots.models';
import {
  checkIfTwentyFourHourIsToggled,
  selectAirportFromAirports,
  selectAirportViewSlot,
  selectCurfewViews,
  selectDropdownFilter,
  selectNewDraftRowSlots,
  selectSlotDetailsEditedAirport,
  selectSlotsDetailsAirports
} from '@features/slots/state/growth-slots.selectors';
import { toArray } from '@utils/utils';
import { convertTimeStringToMinutes } from '@utils/time.utils';
import { ModalService } from '@shared/components/dialogs/modal.service';
import { seasonChangedInSelector } from '@state/app.actions';

@Injectable()
export class GrowthSlotsEffects {
  constructor(
    private actions$: Actions,
    private modalService: ModalService,
    private router: Router,
    private store: Store,
    private toastr: ToastrService,
    private slotsHttpService: SlotsHttpService
  ) {}

  fetchProfilesEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(slotsProfilesPageInitialized, seasonChangedInSelector),
      concatLatestFrom(() => this.store.select(selectSeason)),
      mergeMap(([_action, season]) => {
        return this.slotsHttpService.getGrowthSlotsProfiles(season);
      }),
      map((slotsProfiles) => {
        return apiGrowthSlotsProfilesFetchSuccess({ slotsProfiles });
      })
    );
  });

  clickProfileEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(growthSlotsClickedProfile),
      concatMap((action) =>
        forkJoin([
          of(action),
          fromPromise(this.router.navigate(['slots', action.profile.id, growthSlotsTabs[0].value]))
        ])
      ),
      map(([action]) => growthSlotsClickedProfileSuccess(action))
    );
  });

  // Growth slots Main page effects

  growthSlotsMainPageStartEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(growthSlotsMainPageInitialized),
      concatLatestFrom(() => [this.store.select(selectCurrentRouteState)]),
      map(([, routerState]) => {
        const queryParams = routerState.queryParams;
        const currentTab = routerState.url.split('/').pop().split('?')[0] as SlotsRoute;

        let isLocalTimeZone = true;
        if ('isLocalTimeZone' in queryParams) {
          isLocalTimeZone = queryParams.isLocalTimeZone === 'true';
        }
        // update query params if something is not yet in query params that should be
        this.router.navigate([], {
          queryParams: { isLocalTimeZone },
          queryParamsHandling: 'merge'
        });
        return growthSlotsMainPageInitializedSuccess({
          currentTab: growthSlotsTabs.find((tab) => tab.value === currentTab) ?? growthSlotsTabs[0],
          selectedTabIndex: growthSlotsTabs.findIndex((tab) => tab.value === currentTab),
          slotName: 'slotName' in queryParams ? queryParams.slotName : undefined,
          isLocalTimeZone,
          dropdownFilterAirports:
            'dropdownFilterAirports' in queryParams ? toArray(queryParams.dropdownFilterAirports) : undefined
        });
      })
    );
  });

  growthSlotsSelectionRemovedEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(growthSlotsMainDropdownSelectionRemoved),
      concatLatestFrom(() => [this.store.select(selectDropdownFilter)]),
      map(([action, currentDropdownFilter]) =>
        growthSlotsMainDropdownSelectionRemovedSuccess({
          dropdownFilter: currentDropdownFilter.options.filter((item) => item.code !== action.dropdownOption.code)
        })
      )
    );
  });

  growthSlotsFiltersUpdateEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        growthSlotsMainPageChangedIsLocalTimeZone,
        growthSlotsMainMultipleAirportsSelectionChanged,
        growthSlotsMainDropdownSelectionRemovedSuccess
      ),
      concatMap((action) => {
        const queryParams = {
          isLocalTimeZone: 'isLocalTimeZone' in action ? action.isLocalTimeZone : undefined,
          dropdownFilterAirports:
            'dropdownFilter' in action ? action.dropdownFilter.map((item) => item.code) : undefined
        };
        // remove undefined values, otherwise previous values will be deleted from query if now it's undefined
        Object.keys(queryParams).forEach((key): void => {
          if (queryParams[key] === undefined) {
            delete queryParams[key];
          }
        });
        return forkJoin([
          of(queryParams),
          fromPromise(
            this.router.navigate([], {
              queryParams,
              queryParamsHandling: 'merge'
            })
          )
        ]);
      }),
      map(([queryParams]) => growthSlotsMainFiltersParamsChanged(queryParams))
    );
  });

  tabsChangedEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(growthSlotsMainTabChanged),
      concatLatestFrom(() => this.store.select(selectCurrentRouteState)),
      concatMap(([action, routerState]) => {
        return forkJoin([
          of(action),
          fromPromise(
            this.router.navigate(['slots', routerState.params.id, action.newTab.value], {
              queryParamsHandling: 'merge'
            })
          )
        ]);
      }),
      map(([action]) => growthSlotsMainTabChangedSuccess(action))
    );
  });

  // Airports view slots

  airportsViewLoadSlotsEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        apiGrowthSlotsSlotUpdatedForUpdate,
        apiGrowthSlotsSlotBulkUpdated,
        apiGrowthSlotsSlotUpdated,
        apiGrowthSlotsSlotDetailsAddedNewRow
      ),
      concatLatestFrom(() => [this.store.select(selectCurrentRouteState), this.store.select(selectSeason)]),
      concatMap(([action, routerState, season]): Observable<[MergedRoute, string, string]> => {
        if (
          action.type === apiGrowthSlotsSlotUpdatedForUpdate.type ||
          action.type === apiGrowthSlotsSlotBulkUpdated.type ||
          action.type === apiGrowthSlotsSlotUpdated.type ||
          action.type === apiGrowthSlotsSlotDetailsAddedNewRow.type
        ) {
          return forkJoin([of(routerState), of(season), this.store.select(selectAirportFromAirports).pipe(take(1))]);
        }
      }),
      switchMap(([routerState, season, airportCode]) => {
        return this.slotsHttpService.getGrowthSlotsAirport(routerState.params.id, airportCode, season);
      }),
      map((airportViewSlot) => apiGrowthSlotsSlotsFetchSuccess({ airportSlot: airportViewSlot }))
    );
  });

  airportsViewModalLoadSlotsEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(schedulPlanGrowthSlotsModalDisplayed, schedulPlanGrowthSlotsModalChanged),
      concatLatestFrom(() => [this.store.select(selectSeason)]),
      switchMap(([action, season]) => {
        return this.slotsHttpService.getGrowthSlotsAirport(action.growthSlotProfileId, action.airport, season);
      }),
      map((airportViewSlot) => apiGrowthSlotsSlotsFetchSuccess({ airportSlot: airportViewSlot }))
    );
  });

  airportViewSlotChangedEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(slotsAirportViewZoneChanged),
      concatLatestFrom(() => [this.store.select(selectAirportViewSlot), this.store.select(selectSeason)]),
      concatMap(([action, airportViewSlots, season]) => {
        const slot: UpdateSlotModel = {
          day: [action.day.day],
          hour_array_index: action.i,
          value: action.zone,
          departure: action.slotType === 'departure'
        };
        return this.slotsHttpService
          .updateGrowthSlot(season, airportViewSlots.growth_slot_id, action.airportCode, slot)
          .pipe(
            map(() => {
              this.toastr.success('Slots updated successfully.');
              return apiGrowthSlotsAirportViewSlotChanged(action);
            }),
            catchError(() => {
              return of(apiGrowthSlotsAirportViewSlotChangedFailed());
            })
          );
      })
    );
  });

  // Curfews effects

  curfewsFetchEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        growthSlotsMainFiltersParamsChanged,
        growthSlotsMainPageInitializedSuccess,
        apiGrowthSlotsCurfewsDayChanged,
        apiGrowthSlotsCurfewsUpdated
      ),
      concatLatestFrom(() => [
        this.store.select(selectCurrentRouteState),
        this.store.select(selectDropdownFilter),
        this.store.select(selectSeason)
      ]),
      switchMap(([, routerState, dropdownFilter, currentSeason]) => {
        return this.slotsHttpService.getCurfewAirports(routerState.params.id, dropdownFilter, currentSeason);
      }),
      map((airportCurfews) => {
        return apiGrowthSlotsAirportCurfewsFetched({ airportCurfews });
      })
    );
  });

  curfewsDayClickEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(growthSlotsCurfewsDayClicked),
      concatLatestFrom(() => [
        this.store.select(selectCurfewViews),
        this.store.select(selectCurrentRouteState),
        this.store.select(selectSeason)
      ]),
      switchMap(([action, curfewAirports, routerState, currentSeason]) => {
        const profileId = routerState.params.id;
        const airportToChange = curfewAirports[action.airportRowIndex];
        if (airportToChange.curfew.days.includes(action.day)) {
          return EMPTY;
        }
        const curfewToChange = airportToChange.curfew;
        const newCurfew: Partial<AddCurfew> = {
          arrival_window: curfewToChange.arrival_window,
          departure_window: curfewToChange.departure_window,
          day: [...curfewToChange.days, action.day]
        };
        return this.slotsHttpService.updateCurfew(profileId, airportToChange.airport_code, currentSeason, newCurfew);
      }),
      tap(() => this.toastr.success('Curfew updated successfully.')),
      map((airportCurfews) => {
        return apiGrowthSlotsCurfewsDayChanged({ airportCurfews });
      })
    );
  });

  curfewSaveEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        growthSlotsCurfewsSaveRowClicked,
        growthSlotsCurfewsArrivalDepartureChanged,
        growthSlotsCurfewsConcurrentValueChanged,
        growthSlotsCurfewsToggle24hClicked
      ),
      concatLatestFrom(() => [this.store.select(selectCurrentRouteState), this.store.select(selectSeason)]),
      switchMap(([action, routerState, currentSeason]) => {
        const profileId = routerState.params.id;
        const curfewToChange = action.updatedCurfewView.curfew;
        const updatedCurfew: Partial<AddCurfew> = {
          arrival_window: { ...curfewToChange.arrival_window },
          departure_window: { ...curfewToChange.departure_window },
          day: curfewToChange.days
        };
        if (action.type === growthSlotsCurfewsArrivalDepartureChanged.type) {
          const minutes = convertTimeStringToMinutes(action.value);
          const isNextDay = action.value.includes('+1');
          switch (action.property) {
            case 'first_arrival':
              updatedCurfew.arrival_window.open_minutes = minutes;
              updatedCurfew.arrival_window.open_is_next_day = isNextDay;
              break;
            case 'last_arrival':
              updatedCurfew.arrival_window.close_minutes = minutes;
              updatedCurfew.arrival_window.close_is_next_day = isNextDay;
              break;
            case 'first_departure':
              updatedCurfew.departure_window.open_minutes = minutes;
              updatedCurfew.departure_window.open_is_next_day = isNextDay;
              break;
            case 'last_departure':
              updatedCurfew.departure_window.close_minutes = minutes;
              updatedCurfew.departure_window.close_is_next_day = isNextDay;
              break;
          }
        } else if (action.type === growthSlotsCurfewsConcurrentValueChanged.type) {
          updatedCurfew[action.property] = +action.value;
        } else if (action.type === growthSlotsCurfewsToggle24hClicked.type) {
          const is24ToggledOn = checkIfTwentyFourHourIsToggled(action.updatedCurfewView.curfew);
          updatedCurfew.arrival_window = {
            open_minutes: is24ToggledOn ? 360 : 0,
            close_minutes: is24ToggledOn ? 1380 : 1435,
            open_is_next_day: false,
            close_is_next_day: false
          };
          updatedCurfew.departure_window = {
            open_minutes: is24ToggledOn ? 360 : 0,
            close_minutes: is24ToggledOn ? 1380 : 1435,
            open_is_next_day: false,
            close_is_next_day: false
          };
        }
        return this.slotsHttpService.updateCurfew(
          profileId,
          action.updatedCurfewView.airport_code,
          currentSeason,
          updatedCurfew
        );
      }),
      map((airportCurfews) => {
        this.toastr.success('Curfew updated successfully.');
        return apiGrowthSlotsCurfewsUpdated({ airportCurfews });
      })
    );
  });

  // slots details effects

  fetchSlotsDetailsAirportsEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        growthSlotsMainPageInitializedSuccess,
        growthSlotsMainFiltersParamsChanged,
        apiGrowthSlotsSlotUpdatedForUpdate,
        apiGrowthSlotsSlotBulkUpdated,
        apiGrowthSlotsSlotDetailsAddedNewRow
      ),
      concatLatestFrom(() => [
        this.store.select(selectCurrentRouteState),
        this.store.select(selectDropdownFilter),
        this.store.select(selectSeason)
      ]),
      tap(() => this.modalService.showLoader()),
      switchMap(([, routerState, dropdownFilter, season]) => {
        const profileId = routerState.params.id;
        return this.slotsHttpService.getGrowthSlotsSlots(profileId, dropdownFilter, season);
      }),
      tap(() => this.modalService.hideLoader()),
      map((growthSlotsDetailsAirports) => {
        return apiGrowthSlotsDetailsAirportsFetchSuccess({ growthSlotsDetailsAirports });
      }),
      finalize(() => this.modalService.hideLoader())
    );
  });

  restrictRelaxSlotsEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        growthSlotsSlotDetailsRelaxAllDeparturesClicked,
        growthSlotsSlotDetailsRestricsAllDeparturesClicked,
        growthSlotsSlotDetailsRelaxAllArrivalsClicked,
        growthSlotsSlotDetailsRestrictAllArrivalsClicked
      ),
      concatLatestFrom(() => [this.store.select(selectCurrentRouteState), this.store.select(selectSeason)]),
      switchMap(([action, routerState, season]) => {
        let changeType: 'relax' | 'restrict';
        let slotType: 'arrival' | 'departure';
        switch (action.type) {
          case growthSlotsSlotDetailsRelaxAllDeparturesClicked.type:
            changeType = 'relax';
            slotType = 'departure';
            break;
          case growthSlotsSlotDetailsRelaxAllArrivalsClicked.type:
            changeType = 'relax';
            slotType = 'arrival';
            break;
          case growthSlotsSlotDetailsRestricsAllDeparturesClicked.type:
            changeType = 'restrict';
            slotType = 'departure';
            break;

          case growthSlotsSlotDetailsRestrictAllArrivalsClicked.type:
            changeType = 'restrict';
            slotType = 'arrival';
            break;
        }
        const slots: number[] = Array(24).fill(changeType === 'relax' ? null : 0);
        let object: Partial<AddCurfew>;
        switch (slotType) {
          case 'arrival':
            object = { day: action.slotItem.slot.days, arrival_slots_per_hour: slots };
            break;
          case 'departure':
            object = { day: action.slotItem.slot.days, departure_slots_per_hour: slots };
            break;
        }
        const profileId = routerState.params.id;
        return this.slotsHttpService.updateCurfew(profileId, action.slotItem.airport, season, object);
      }),
      tap(() => this.toastr.success('Slots updated successfully.')),
      map((airportSlot) => {
        return apiGrowthSlotsSlotUpdatedForUpdate({ airportSlot: airportSlot });
      })
    );
  });

  updateSlotDaysEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(growthSlotsSlotDetailsExistingDaysChanged),
      concatLatestFrom(() => [this.store.select(selectCurrentRouteState), this.store.select(selectSeason)]),
      switchMap(([action, routerState, season]) => {
        const profileId = routerState.params.id;
        const object: Partial<AddCurfew> = {
          day: action.days,
          arrival_slots_per_hour: action.slots.arrival_slots_per_hour,
          departure_slots_per_hour: action.slots.departure_slots_per_hour
        };
        return this.slotsHttpService.updateCurfew(profileId, action.airport, season, object);
      }),
      tap(() => this.toastr.success('Slots updated successfully.')),
      map((airportSlot) => {
        return apiGrowthSlotsSlotUpdatedForUpdate({ airportSlot: airportSlot });
      })
    );
  });

  saveNewRowEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(growthSlotsSlotDetailsAddNewRowSave),
      concatLatestFrom(() => [
        this.store.select(selectCurrentRouteState),
        this.store.select(selectSeason),
        this.store.select(selectSlotDetailsEditedAirport),
        this.store.select(selectNewDraftRowSlots)
      ]),
      switchMap(([, routerState, season, editedAirport, newRowAirport]) => {
        const profileId = routerState.params.id;

        if (!newRowAirport.days.length) {
          this.toastr.warning('Select at least one day');
          return EMPTY;
        }
        const newCurfew: Partial<AddCurfew> = {
          ...newRowAirport,
          day: newRowAirport.days
        };
        return this.slotsHttpService.updateCurfew(profileId, editedAirport, season, newCurfew);
      }),
      tap(() => this.toastr.success('Slots updated successfully.')),
      map(() => apiGrowthSlotsSlotDetailsAddedNewRow())
    );
  });

  updateCurfewEffect = createEffect(() => {
    let actionData;
    return this.actions$.pipe(
      ofType(growthSlotsSlotDetailsExistingZonesChanged),
      concatLatestFrom(() => [this.store.select(selectCurrentRouteState), this.store.select(selectSeason)]),
      switchMap(([action, routerState, season]) => {
        const profileId = routerState.params.id;
        actionData = action;
        const slot: UpdateSlotModel = {
          day: actionData.curfew.day,
          hour_array_index: actionData.i,
          value: actionData.zone,
          departure: actionData.slotType === 'departure'
        };
        return this.slotsHttpService.updateGrowthSlot(season, profileId, actionData.airport, slot);
      }),
      tap(() => this.toastr.success('Slots updated successfully.')),
      map(() =>
        apiGrowthSlotsSlotUpdated({
          curfew: actionData.curfew,
          slotType: actionData.slotType,
          airport: actionData.airport
        })
      )
    );
  });

  cloneToAllEffect = createEffect(() => {
    return this.actions$.pipe(
      ofType(growthSlotsSlotDetailsCloneToAll),
      concatLatestFrom(() => [
        this.store.select(selectSlotsDetailsAirports),
        this.store.select(selectCurrentRouteState),
        this.store.select(selectSeason)
      ]),
      tap(() => this.modalService.showLoader('Cloning airport codes...')),
      switchMap(([action, airports, routerState, season]) => {
        const profileId = routerState.params.id;

        const otherAirports = airports.filter((airport) => airport.airport_code !== action.selectedAirportItem.airport);
        const sourceObjectToClone = {
          day: action.selectedAirportItem.slot.days,
          departure_slots_per_hour: action.selectedAirportItem.slot.departure_slots_per_hour,
          arrival_slots_per_hour: action.selectedAirportItem.slot.arrival_slots_per_hour,
          airport_codes: otherAirports.map((airport) => airport.airport_code)
        };
        return this.slotsHttpService.updateCurfewBulk(profileId, season, sourceObjectToClone);
      }),
      tap(() => {
        this.modalService.hideLoader();
        this.toastr.success('Data cloned successfully.');
      }),
      map((curfewDto) => {
        return apiGrowthSlotsSlotBulkUpdated({ curfewDto });
      })
    );
  });
}
