import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigEndpoint } from '@shared/services/config-endpoint-decorator';
import { UserView, UserUpdateData } from '../users.model';

@Injectable({ providedIn: 'root' })
@ConfigEndpoint({ version: 2 })
export class UsersHttpService {
  private rootEndPoint = 'users';
  private apiUrl: string;

  constructor(private httpClient: HttpClient) {}

  getUser(id: number): Observable<UserView> {
    return this.httpClient.get<UserView>(`${this.apiUrl}/${this.rootEndPoint}/${id}`);
  }

  updateUser(id: number, user: UserUpdateData): Observable<UserView> {
    return this.httpClient.put<UserView>(`${this.apiUrl}/${this.rootEndPoint}/${id}`, user);
  }

  deleteUser(id: number): Observable<number> {
    return this.httpClient.delete<number>(`${this.apiUrl}/${this.rootEndPoint}/${id}`);
  }

  getAllUsers(): Observable<UserView[]> {
    return this.httpClient.get<UserView[]>(`${this.apiUrl}/${this.rootEndPoint}`);
  }

  addUser(user: UserUpdateData): Observable<UserView> {
    return this.httpClient.post<UserView>(`${this.apiUrl}/${this.rootEndPoint}`, user);
  }

  toggleFavoriteScreen(userId: number, screenName: string): Observable<UserView> {
    return this.httpClient.put<UserView>(
      `${this.apiUrl}/${this.rootEndPoint}/${userId}/toggle-favorite-screen?screenName=${screenName}`,
      null
    );
  }
}
