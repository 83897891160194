import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UsersViewModel, UserUpdateData, UserView } from '../users.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DataGridColumn } from '@shared/components/data-grid/data-grid.interface';
import { UsersConfiguration } from '../services/users-grid.service';
import DataSource from 'devextreme/data/data_source';
import { DataGridComponent } from '@shared/components/data-grid/data-grid.component';
import { selectUsersViewModel } from '../state/users.selectors';
import {
  addUserButtonClicked,
  deleteUserButtonClicked,
  updateUserButtonClicked,
  usersScreenInitialized
} from '../state/users.actions';
import { Store } from '@ngrx/store';
import { selectIsReadOnlyUser } from '@state/app.selectors';
import { filter, Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { DxDataGridModule, DxTagBoxModule, DxTextBoxModule } from 'devextreme-angular';
import { cloneDeep } from 'lodash';
import { SectionTitleComponent } from '@shared/components/section-title/section-title.component';

@UntilDestroy()
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    DxDataGridModule,
    DxTagBoxModule,
    DxTextBoxModule,
    SharedModule,
    SectionTitleComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsersComponent implements OnInit {
  @ViewChild(DataGridComponent, { static: false }) sharedDataGrid: DataGridComponent<UsersComponent>;

  userIsReadOnly$: Observable<boolean> = this.store.select(selectIsReadOnlyUser);
  users: UserView[] = [];
  selectedUserId: number | string;
  columns: DataGridColumn[] = [];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.store.dispatch(usersScreenInitialized());
    this.executeLoad();
  }

  executeLoad(): void {
    this.store
      .select(selectUsersViewModel)
      .pipe(
        untilDestroyed(this),
        filter((viewModel) => !!viewModel)
      )
      .subscribe((viewModel: UsersViewModel) => {
        this.users = cloneDeep(viewModel.users);
        this.generateColumns(viewModel.userRoles, viewModel.isSupportAccount);
      });
  }

  generateColumns(userRoles: DataSource, isSupportAccount: boolean): void {
    this.columns = UsersConfiguration.getUsersConfiguration(userRoles, isSupportAccount);
    this.changeDetectorRef.detectChanges();
  }

  rowUpdated({ changes }: { changes: { type: string; data: UserUpdateData; key: number }[] }): void {
    if (!changes.length) return;

    const type = changes[0].type;
    const rowData = changes[0].data;

    switch (type) {
      case 'insert':
        this.confirmAddRow(rowData);
        break;
      case 'update':
        this.confirmUpdateRow(rowData);
        break;
      case 'remove':
        this.confirmDeleteRow();
        break;
    }
  }

  addNewRowClicked(): void {
    this.sharedDataGrid.addNewRow();
  }

  confirmAddRow(user: UserUpdateData): void {
    delete user.id;
    user.enabled = true;
    this.store.dispatch(addUserButtonClicked({ user }));
  }

  confirmUpdateRow(user: UserUpdateData): void {
    this.store.dispatch(updateUserButtonClicked({ user }));
  }

  confirmDeleteRow(): void {
    this.store.dispatch(deleteUserButtonClicked({ userId: +this.selectedUserId }));
  }

  deleteRow(cell: UserView): void {
    this.selectedUserId = cell?.id;
  }

  usernameValidation(user: UserUpdateData): boolean {
    return this.users.some((u) => u?.username === user?.username && u?.id !== user?.id);
  }
}
