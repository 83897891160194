import { createSelector, createFeatureSelector } from '@ngrx/store';
import { UsersState } from './users.state';
import { selectHasPermissions } from '@state/app.selectors';
import { USER_ROLES, UsersViewModel } from '../users.model';
import DataSource from 'devextreme/data/data_source';
export const selectUsersState = createFeatureSelector<UsersState>('users');

export const selectUsers = createSelector(selectUsersState, (state) => state.users);

export const selectUsersViewModel = createSelector(
  selectUsers,
  selectHasPermissions('support'),
  (users, isSupportAccount): UsersViewModel => {
    if (!users) return undefined;

    const rolesArray = USER_ROLES.map((role) => ({ name: role, value: role }));
    const userRoles = new DataSource({
      store: {
        data: rolesArray,
        type: 'array'
      },
      paginate: true
    });

    return {
      users,
      isSupportAccount,
      userRoles
    };
  }
);
