import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, createUrlTreeFromSnapshot, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { StorageRecordName, StorageService } from '@shared/services/storage/storage.service';
import { AuthorizationTokensDto } from '@authorization/services/auth-token.models';

export const authGuard = (next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> => {
  const storageService = inject(StorageService);
  return storageService.get<AuthorizationTokensDto>(StorageRecordName.UserAccessTokens).pipe(
    map((loginResponseTokens) => {
      if (loginResponseTokens) {
        return true;
      } else {
        return createUrlTreeFromSnapshot(next, ['/sign-in']);
      }
    })
  );
};
