import { createAction, props } from '@ngrx/store';
import { UserView, UserUpdateData } from '../users.model';

export const usersScreenInitialized = createAction('[Users] Screen Initialized');
export const apiLoadUsersSuccess = createAction('[Users API] Load Users Success', props<{ users: UserView[] }>());
export const apiLoadUsersFailed = createAction('[Users API] Load Users Failed');

export const addUserButtonClicked = createAction('[Users] Add User button clicked', props<{ user: UserUpdateData }>());
export const apiAddUserSuccess = createAction('[Users API] Add User Success', props<{ user: UserView }>());
export const addUserFailed = createAction('[Users API ] Add User Failed');

export const updateUserButtonClicked = createAction(
  '[Users] Update User button clicked',
  props<{ user: UserUpdateData }>()
);
export const apiUpdateUserSuccess = createAction('[Users API] Update User Success', props<{ user: UserView }>());
export const apiUpdateUserFailed = createAction('[Users API] Update User Failed');

export const deleteUserButtonClicked = createAction('[Users] Delete User button clicked', props<{ userId: number }>());
export const apiDeleteUserSuccess = createAction('[Users API] Delete User Success', props<{ userId: number }>());
export const apiDeleteUserFailed = createAction('[Users API] Delete User Failed');
