<div class="users-wrapper fixed-screen-height">
  <div class="heading">
    <app-section-title>Users</app-section-title>
    @if ((userIsReadOnly$ | async) === false) {
      <app-button
        [iconClass]="'icon-add'"
        [hideLabel]="true"
        [noLabel]="true"
        (click)="addNewRowClicked()"></app-button>
    }
  </div>

  @if (users) {
    <div class="grid-box full-size-container">
      <app-data-grid
        id="grid"
        class="full-size-container"
        [dataSource]="users"
        [columns]="columns"
        keyExpr="id"
        [editingMode]="'row'"
        [uniqueColumn]="'username'"
        [showColumnLines]="false"
        [optionsMenu]="['edit', 'delete']"
        [pagingEnabled]="false"
        [fixedHeight]="true"
        (rowUpdatedEvent)="rowUpdated($event)"
        (rowDeleted)="deleteRow($event)">
      </app-data-grid>
    </div>
  }
</div>
